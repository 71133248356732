import React, { useEffect, useState } from "react";
import { deleteDocument, fetchAll, getDate } from "../../helpers/helpers";
import * as XLSX from "xlsx";

const ContactsPage = () => {
  const [list, setList] = useState([]);
  const getMessages = async () => {
    let d = await fetchAll("contacts");
    setList(
      d
        .sort((a, b) => {
          if (a.timeOfCreation.seconds === b.timeOfCreation.seconds) {
            return a.timeOfCreation.nanoseconds - b.timeOfCreation.nanoseconds;
          } else {
            return a.timeOfCreation.seconds - b.timeOfCreation.seconds;
          }
        })
        .reverse()
    );
  };

  useEffect(() => {
    getMessages();
    console.log(list)
  }, []);

  const handleDelete = (id) => {
    deleteDocument("contacts", id);
    getMessages();
  };

  const handleExport = () => {
    // Transform data to a more suitable format for XLSX
    const transformedData = list.map((item) => ({
      Firstname: item.firstName,
      Lastname: item.lastName,
      message: item.message,
      Email: item.email,
      Phone: item.phone,
      ID: item.id,
      "Time of Creation": getDate(item.timeOfCreation),
    }));

    // Create a new workbook and a new worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    // Generate XLSX file and trigger download
    XLSX.writeFile(workbook, "export.xlsx");
  };

  // return (
  //   <div>
  //     <div className="overflow-x-auto">
  //       <div className="px-12 pt-12 flex flex-row gap-20 items-center">
  //         <h1 className="font-bold text-1xl">Contacts Count: {list.length}</h1>
  //         <button
  //           className="btn btn-neutral"
  //           onClick={() => {
  //             handleExport();
  //           }}
  //         >
  //           Export XLSX
  //         </button>
  //       </div>
  //       <table className="table mt-16">
  //         {/* head */}
  //         <thead>
  //           <tr>
  //             <th>Firstname</th>
  //             <th>Lastname</th>
  //             <th>Email</th>
  //             <th>Phone</th>
  //             <th>Message</th>
  //             <th>TOC</th>
  //             <th></th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {list.map((item) => (
  //             <tr key={item.id}>
  //               <td>{item.firstName}</td>
  //               <td>{item.lastName}</td>
  //               <td>{item.email}</td>
  //               <td>{item.phone}</td>
  //               <td>{item.message}</td>
  //               {item.timeOfCreation && <td>{getDate(item.timeOfCreation)}</td>}
  //               <td>
  //                 <button
  //                   className="btn btn-outline btn-secondary"
  //                   onClick={() => {
  //                     handleDelete(item.id);
  //                   }}
  //                 >
  //                   Delete
  //                 </button>
  //               </td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     </div>
  //   </div>
  // );

  return (
    <div className="p-6">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h1 className="text-lg font-bold">Contacts Count: {list.length}</h1>
        <button
          className="btn btn-neutral mt-4 md:mt-0"
          onClick={handleExport}
        >
          Export XLSX
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>#</th>
              <th>Firstname</th>
              <th>Lastname</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Message</th>
              <th>Time of Creation</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={item.id} className="hover:bg-gray-100">
                <th>{index + 1}</th>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.email}</td>
                <td>{item.phone}</td>
                <td>{item.message}</td>
                <td>{getDate(item.timeOfCreation)}</td>
                <td>
                  <button
                    className="btn btn-outline btn-secondary"
                    onClick={() => handleDelete(item.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactsPage;
