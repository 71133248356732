import HeroImage from '../../assets/images/heroSectionImage.webp'
function HeroSection() {
  return (
    <div className='flex flex-col justify-center items-center md:flex-row mt-10'>
        <div className="flex flex-col items-center justify-center gap-4">
            <h1 className='text-3xl md:text-4xl lg:text-6xl font-bold text-[#0c3c7d]'>Happy Mama Tales</h1>
            <p className='text-sm md:text-lg'>Connecting women through stories and tutorials</p>
        </div>
        <div className="">
            <img src={HeroImage} alt="" />
        </div>
    </div>
  )
}

export default HeroSection