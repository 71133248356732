import React, {useState, useEffect} from 'react'
import { fetchAll } from '../helpers/helpers'

import HorizontalBarChart from "./HorizontalBarChart"

const Analytics = () => {

    const [contactsList, setContactsList] = useState([])
    const [messagesList, setMessagesList] = useState([])
    const [newsletterList, setNewsletterList] = useState([])
    const [visitors, setVisitors] = useState([])
    const [orders, setOrders] = useState([])


    const fetchData = async () => {
        let c = await fetchAll("contacts")
        let m = await fetchAll("messages")
        let n = await fetchAll("newsletter")
        let o = await fetchAll("ordersAnalytics")


        setContactsList(c);
        setMessagesList(m)
        setNewsletterList(n)
        setOrders(o[0].value)
        setVisitors(o[1].value)
    }

    useEffect(() => {
        fetchData()
    }, [])

    

  return (
    <div className='w-full flex flex-col gap-4 items-center'>
        <h1 className='text-2xl font-bold'>Analytics</h1>
    <div className='w-3/4 flex flex-col items-start'>
        <HorizontalBarChart data={[contactsList.length, messagesList.length, newsletterList.length, visitors]} />
    </div>
    <div className="flex justify-around gap-16">
    <div className='text-3xl font-bold'>
        <h1>Total Order Clicks: {orders}</h1>
    </div>
    <div className='text-3xl font-bold'>
        <h1>Total Website Visitors: {visitors}</h1>
    </div>
    </div>
    </div>
  )
}

export default Analytics