import React, { useState } from "react";

const ProductImageUploader = ({ setProductImages }) => {
  const [images, setImages] = useState([]); // This will store the File objects
  const [previewImages, setPreviewImages] = useState([]); // This will store the image preview URLs

  // Handle image file change
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to an array

    // Set preview URLs for images
    const newPreviewImages = files.map((file) => URL.createObjectURL(file));
    setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);

    // Store files in state
    setImages((prevImages) => [...prevImages, ...files]);
  };

  // Function to handle form submission (for your backend processing)
  const handleSubmit = () => {
    console.log("Files to upload:", images);
    setProductImages(images);
    alert("IMAGES UPLOADED");
  };

  // Remove an image
  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
    setPreviewImages(previewImages.filter((_, i) => i !== index));
  };

  return (
    <div className="max-w-xl mx-auto p-4">
      <h2 className="text-xl font-semibold mb-4">Upload Product Images</h2>
      <input
        type="file"
        multiple
        onChange={handleImageChange}
        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
      />
      <div className="flex flex-wrap gap-4 mt-4">
        {previewImages.map((image, index) => (
          <div key={index} className="relative">
            <img
              src={image}
              alt={`Preview ${index}`}
              className="w-32 h-32 object-cover rounded-md"
            />
            <button
              onClick={() => removeImage(index)}
              type="button"
              className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 text-xs"
            >
              X
            </button>
          </div>
        ))}
      </div>
      <button
        onClick={handleSubmit}
        type="button"
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
      >
        Upload Images
      </button>
    </div>
  );
};

export default ProductImageUploader;
