import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { fetchSingleProduct, updateProduct } from "../../helpers/helpers"; // Ensure updateProduct is implemented
import { FaArrowLeftLong } from "react-icons/fa6";

const EditProductPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    title: "",
    price: "",
    shortDes: "",
    longDes: "",
    amazonLink: "",
    category: "",
    tags: "",
  });

  useEffect(() => {
    const fetchProductData = async () => {
      setLoading(true);
      try {
        const productData = await fetchSingleProduct(id);
        console.log("Fetched product data:", productData); // Debug log
        if (productData !== "No such product found!") {
          setProduct(productData);
          setFormData({
            title: productData.title || "",
            price: productData.price || "",
            shortDes: productData.shortDes || "",
            longDes: productData.longDes || "",
            amazonLink: productData.amazonLink || "",
            category: productData.category || "",
            tags: productData.tags || "",
          });
        } else {
          // Handle the case when no product is found
          alert("Product not found!");
        }
      } catch (error) {
        console.error("Failed to fetch product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateProduct(id, formData);
      alert("Product updated successfully!");
    } catch (error) {
      console.error("Failed to update product:", error);
      alert("Failed to update product.");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-blue-600" />
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center max-w-7xl mx-auto gap-2">
      <Link to="/admin/products">
        <div className="flex flex-row items-center px-2 gap-2 mb-4">
          <FaArrowLeftLong /> Back to Products
        </div>
      </Link>
      <h1 className="py-3 font-bold text-3xl">Edit Product</h1>
      {product ? (
        <form onSubmit={handleSubmit} className="space-y-4 w-full">
          <div className="flex flex-col">
            <label className="block font-bold">Title:</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="border p-2 w-full "
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="block font-bold">Price:</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              className="border p-2 w-full "
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="w-auto text-nowrap font-bold">
              Short Description:
            </label>
            <textarea
              name="shortDes"
              value={formData.shortDes}
              onChange={handleChange}
              className="border p-2 w-full"
              rows="3"
            />
          </div>
          <div className="flex flex-col">
            <label className="w-auto text-nowrap font-bold">
              Long Description:
            </label>
            <textarea
              name="longDes"
              value={formData.longDes}
              onChange={handleChange}
              className="border p-2 w-full"
              rows="5"
            />
          </div>
          <div className="flex flex-col">
            <label className="w-auto text-nowrap font-bold">Amazon Link:</label>
            <input
              type="text"
              name="amazonLink"
              value={formData.amazonLink}
              onChange={handleChange}
              className="border p-2 w-full"
            />
          </div>
          <div className="flex flex-col">
            <label className="w-auto text-nowrap font-bold">Category:</label>
            <input
              type="text"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="border p-2 w-full"
            />
          </div>
          <div>
            <label className="block font-bold">Tags (comma-separated):</label>
            <input
              type="text"
              name="tags"
              value={formData.tags}
              onChange={handleChange}
              className="border p-2 w-full"
            />
          </div>
          <div>
            <label className="block font-bold">Images:</label>
            {product.images && product.images.length > 0 ? (
              <div className="flex flex-wrap gap-2">
                {product.images.map((imgUrl, index) => (
                  <img
                    key={index}
                    src={imgUrl}
                    alt={`Product Image ${index + 1}`}
                    className="w-24 h-24 object-cover"
                  />
                ))}
              </div>
            ) : (
              <p>No images available</p>
            )}
          </div>
          <button type="submit" className="bg-blue-600 text-white p-2 rounded">
            Save Changes
          </button>
        </form>
      ) : (
        <p>No product found</p>
      )}
    </div>
  );
};

export default EditProductPage;
