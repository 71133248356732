import React from "react";
import BritniImage from "../../assets/images/Britni.jpg";
import { Link } from "react-router-dom";

function Britni() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center gap-6 md:gap-8">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded transition-transform duration-300 hover:scale-105"
            alt="britni"
            src={BritniImage}
          />
        </div>
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            Hi, I'm Britni
          </h1>
          <p className="mb-4 leading-relaxed">
            I'm on a mission to encourage women all throughout the world to
            believe that YOU ARE ENOUGH and that you can be happy in the midst
            of all the struggles life has to offer!
          </p>
          <p className="mb-4 leading-relaxed">
            I'm passionate about creating a space for honesty, vulnerability and
            community where we can share stories, connect on a global level,
            share some laughs and even learn a thing or two along the way.
          </p>
          <p className="mb-4 leading-relaxed">
            Together we will be the strongest (in all the ways) mamas this world
            has ever seen as we stand tall and stand together to raise the next
            generation!
          </p>
          <div classNameName="flex items-center justify-center py-4">
            <Link
              className="py-3 px-6 bg-[#f1a6a8] rounded-md hover:bg-opacity-90"
              to={"/about-us"}
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Britni;
