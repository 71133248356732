import React, { useState } from 'react';
import { addDocToNewsletter } from '../../helpers/helpers';
import toast from 'react-hot-toast';

const Newsletter = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDocToNewsletter({ email });
      toast('Thank you for subscribing to our newsletter!',
  {
    icon: '📧',
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff',
      textAlign:'center'
    },
  }
);
      setEmail('');
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('There was an error. Please try again.');
    }
  };

  return (
    <div className="w-full py-16 text-white bg-[#000300] px-4">
      <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3">
        <div className="lg:col-span-2 my-4">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Get 15% OFF First Delivery!
          </h1>
          <p>Sign up to our newsletter and stay up to date.</p>
        </div>
        <div className="my-4">
          <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row items-center justify-between w-full">
            <input
              className="p-3 flex w-full rounded-md text-black"
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              type="submit"
              className="bg-[#00df9a] text-black rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-3"
            >
              Notify Me
            </button>
          </form>
          <p>We care about the protection of your data.</p>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
