import React from 'react'
import Banner from '../components/Shop/Banner'
import Products from '../components/Home/Products'

function ShopPage() {
  return (
    <div>
        <Banner/>
        <Products/>
    </div>
  )
}

export default ShopPage