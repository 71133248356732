import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../config/firebase";
import { Link } from "react-router-dom";

function BlogCard({ title, text, imageUrl, id }) {
  console.log(title, text, imageUrl);

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div className="relative">
        <img className="w-full h-64 object-cover" src={imageUrl} alt={title} />
      </div>
      <div className="p-4">
        <div className="text-lg font-medium text-gray-800 mb-2 text-center">
          {title}
        </div>
      </div>
      <div className="flex items-center justify-center mb-2">
        <Link
          to={`/blog/${id}`}
          className="text-center hover:underline text-blue-900"
        >
          Read More
        </Link>
      </div>
    </div>
  );
}

function Blog() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const blogCollection = collection(db, "blogPosts");
      const blogSnapshot = await getDocs(blogCollection);
      const blogList = blogSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogs(blogList);
    };

    fetchBlogs();
  }, []);

  return (
    <>
      <div className="text-center p-6">
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold">
          Our Blogs
        </h1>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-10 md:px-20">
        {blogs.map((blog) => (
          <BlogCard
            key={blog.id}
            title={blog.title}
            imageUrl={blog.mainImage}
            text={blog.text}
            id={blog.id}
          />
        ))}
      </div>
    </>
  );
}

export default Blog;
