import React, { useEffect, useState } from "react";
import { deleteDocument, fetchAll, getDate } from "../../helpers/helpers";
import * as XLSX from "xlsx";

const NewsletterPage = () => {
  const [list, setList] = useState([]);
  const getMessages = async () => {
    let d = await fetchAll("newsletter");
    setList(
      d
        .sort((a, b) => {
          if (a.timeOfCreation.seconds === b.timeOfCreation.seconds) {
            return a.timeOfCreation.nanoseconds - b.timeOfCreation.nanoseconds;
          } else {
            return a.timeOfCreation.seconds - b.timeOfCreation.seconds;
          }
        })
        .reverse()
    );
  };

  useEffect(() => {
    getMessages();
  }, []);

  const handleDelete = (id) => {
    deleteDocument("newsletter", id);
    getMessages();
  };

  const handleExport = () => {
    // Transform data to a more suitable format for XLSX
    const transformedData = list.map((item) => ({
      Email: item.email,
      ID: item.id,
      "Time of Creation": getDate(item.timeOfCreation),
    }));

    // Create a new workbook and a new worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    // Generate XLSX file and trigger download
    XLSX.writeFile(workbook, "export.xlsx");
  };

  return (
    <div className="p-6">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h1 className="text-lg font-bold">
          Newsletter Enquiry Count: {list.length}
        </h1>
        <button
          className="btn btn-neutral mt-4 md:mt-0"
          onClick={handleExport}
        >
          Export XLSX
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>#</th>
              <th>Email</th>
              <th>Time of Creation</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={item.id} className="hover:bg-gray-100">
                <th>{index + 1}</th>
                <td>{item.email}</td>
                <td>{getDate(item.timeOfCreation)}</td>
                <td>
                  <button
                    className="btn btn-outline btn-secondary"
                    onClick={() => handleDelete(item.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewsletterPage;
