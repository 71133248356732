import React, { useState, useEffect } from "react";
import { fetchAll } from "../../helpers/helpers";

// Components
import AddProduct from "../../components/AddProduct";
import Product from "../../components/Product";

const ProductsListPage = () => {
  const [products, setProducts] = useState([]);

  const getList = async () => {
    let p = await fetchAll("products");
    setProducts(p);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div>
      <div className="flex flex-row gap-4 items-center">
        <AddProduct getList={getList} />
        <button className="btn btn-primary" onClick={() => getList()}>
          Refresh
        </button>
      </div>

      <div className="mx-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {products.map((product) => {
          return (
            <Product
              key={product.id}
              title={product.title}
              description={product.description}
              price={product.price}
              amazonLink={product.amazonLink}
              category={product.category}
              tags={product.tags}
              images={product.images}
              timeOfCreation={product.timeOfCreation}
              productId={product.productId}
              id={product.id}
              getList={getList}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProductsListPage;
