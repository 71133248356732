// src/components/BlogList.js
import React, { useEffect, useState } from "react";
import { db } from "../../config/firebase";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { Link } from "react-router-dom";

const AdminBlogPage = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const blogCollection = collection(db, "blogPosts");
      const blogSnapshot = await getDocs(blogCollection);
      const blogList = blogSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogs(blogList);
    };

    fetchBlogs();
  }, []);

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this blog post?"
    );
    if (confirmed) {
      await deleteDoc(doc(db, "blogPosts", id));
      setBlogs(blogs.filter((blog) => blog.id !== id));
    }
  };

  return (
    <div className="max-w-6xl mx-auto mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {blogs.map((blog) => (
        <div key={blog.id} className="card w-full bg-base-100 shadow-xl">
          <figure>
            {blog.mainImage ? (
              <img
                src={blog.mainImage}
                alt="Blog Cover"
                className="w-full h-48 object-cover"
              />
            ) : (
              <div className="w-full h-48 flex items-center justify-center bg-gray-200">
                <span>No Image</span>
              </div>
            )}
          </figure>
          <div className="card-body">
            <h2 className="card-title">{blog.title}</h2>
            <div className="card-actions justify-end space-x-2">
              <Link to={`/admin/blog/${blog.id}`} className="btn btn-primary">
                Read More
              </Link>
              <Link
                to={`/admin/editblog/${blog.id}`}
                className="btn btn-primary"
              >
                Edit
              </Link>
              <button
                onClick={() => handleDelete(blog.id)}
                className="btn btn-error"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdminBlogPage;
