import React from 'react'
import Contact from '../components/Contact/Contact'

function ContactPage() {
  return (
    <div>
        <Contact/>
    </div>
  )
}

export default ContactPage