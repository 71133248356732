import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { fetchSingleProduct } from "../../helpers/helpers";
import Slider from "react-slick";

function ProductInfo() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductData = async () => {
      setLoading(true);
      try {
        const productData = await fetchSingleProduct(productId);
        setProduct(productData);
        console.log(productData);
      } catch (error) {
        console.error("Failed to fetch product:", error);
        toast.error("Failed to load product details.");
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [productId]);

  // Slider settings for react-slick
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-blue-600" />
      </div>
    );
  }

  if (!product) {
    return (
      <div className="text-center text-xl font-semibold text-red-500">
        Product not found.
      </div>
    );
  }

  return (
    <div className="font-sans bg-white mt-4">
      <div className="p-4 lg:max-w-7xl max-w-4xl mx-auto">
        <div className="grid items-start grid-cols-1 lg:grid-cols-5 gap-8 lg:gap-12 shadow-lg p-6 rounded-lg">
          {/* Image slider with responsive design */}
          <div className="lg:col-span-3 w-full lg:sticky top-0 text-center">
            <div className="px-4 py-8 lg:py-10 rounded-lg shadow-lg relative">
              {/* Slick slider carousel */}
              <Slider {...sliderSettings}>
                {product.images.map((image, index) => (
                  <div
                    key={index}
                    className="flex justify-center items-center mx-auto"
                  >
                    {/* Fixed size for images */}
                    <img
                      src={image}
                      alt={`Slide ${index + 1}`}
                      className="w-full object-cover"
                      style={{
                        height: "400px",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          {/* Product Details Section */}
          <div className="lg:col-span-2">
            <h2 className="text-2xl font-extrabold text-gray-800">
              {product.title}
            </h2>

            <p className="text-lg font-medium mt-2">${product.price}</p>

            {/* Display Category */}
            <div className="mt-4">
              <h3 className="text-xl font-semibold text-gray-900">Category:</h3>
              <p className="text-gray-700">{product.category}</p>
            </div>

            {/* Display Tags */}
            <div className="mt-4">
              <h3 className="text-xl font-semibold text-gray-900">Tags:</h3>
              <div className="flex flex-wrap gap-2 mt-2">
                {product.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="inline-block bg-gray-200 text-gray-800 text-sm px-3 py-1 rounded-full"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>

            <div className="mt-4">
              <h3 className="text-xl font-semibold text-gray-900">
                Description:
              </h3>
              <div className="flex flex-col">
                <p className="py-2 font-semibold">Short Description</p>
                <p className="text-gray-700">{product.shortDes}</p>
                <p className="py-2 font-semibold">Long Description</p>
                <p className="text-gray-700">{product.longDes}</p>
              </div>
            </div>

            {/* Buy Now Button */}
            <div className="mt-6">
              <Link
                className="inline-flex items-center justify-center py-3 text-base font-medium text-center bg-[#f1a6a8] text-white border border-transparent rounded-full px-7 hover:bg-opacity-90"
                to={product.amazonLink}
                target="_blank"
              >
                Buy Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductInfo;
