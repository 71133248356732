import React from "react";
import { FaFacebook, FaInstagram, FaPinterest } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer footer-center bg-gray-200 text-base-content rounded p-10">
      <nav className="grid grid-flow-row sm:grid-flow-col gap-4 text-sm text-gray-400">
        <Link className="link link-hover" to={"/"}>
          Home
        </Link>
        <Link className="link link-hover" to={"/blog"}>
          Blog
        </Link>
        <Link className="link link-hover" to={"/about-us"}>
          About us
        </Link>
        <Link className="link link-hover" to={"/learn-to-serge"}>
          Learn To Serge
        </Link>
        <Link className="link link-hover" to={"/store"}>
          HMT Essentials Store
        </Link>
        <Link className="link link-hover" to={"/contact"}>
          Contact
        </Link>
      </nav>
      <nav>
        <div className="grid grid-flow-col gap-6">
          <Link className="hover:scale-110 hover:duration-200 hover:text-red-700">
            <FaPinterest size={30} />
          </Link>
          <Link className="hover:scale-110 hover:duration-200 hover:text-blue-700">
            <FaFacebook size={30} />
          </Link>
          <Link className="hover:scale-110 hover:duration-200 hover:text-red-600">
            <FaInstagram size={30} />
          </Link>
          <Link className="hover:scale-110 hover:duration-200 hover:text-gray-950">
            <FaXTwitter size={30} />
          </Link>
        </div>
      </nav>
      <aside>
        <p>
          © {new Date().getFullYear()} - Happy Mama Tales. Website Created in
          co-operation with <span className="font-medium">webwisedigital </span>{" "}
        </p>
      </aside>
    </footer>
  );
}

export default Footer;
