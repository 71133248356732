import React from "react";
import { FaRegHeart } from "react-icons/fa";
import Image1 from "../../assets/images/mother1.svg";
import Image2 from "../../assets/images/mother2.webp";
import Image3 from "../../assets/images/mother3.webp";
import { Link } from "react-router-dom";

function Description() {
  return (
    <section className="overflow-hidden pt-20 pb-12 lg:pt-[100px] lg:pb-[90px] bg-gray-100">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center justify-between">
          <div className="w-full px-4 md:px-6 lg:w-6/12">
            <div className="flex items-center -mx-3 sm:-mx-4">
              <div className="w-full px-3 sm:px-4 xl:w-1/2">
                <div className="py-3 sm:py-4">
                  <img src={Image1} alt="" className="w-full rounded-2xl" />
                </div>
                <div className="py-3 sm:py-4">
                  <img src={Image2} alt="" className="w-full rounded-2xl" />
                </div>
              </div>
              <div className="w-full px-3 sm:px-4 xl:w-1/2">
                <div className="relative z-10 my-4">
                  <img src={Image3} alt="" className="w-full rounded-2xl" />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/2 xl:w-5/12 p-6">
            <div className="mt-10 lg:mt-0 text-black">
              <div className="flex justify-center items-center p-3">
                <FaRegHeart size={45} className="text-[#f1a6a8] " />
              </div>
              <p className="mb-5 text-gray-600 text-xl">
                Welcome to Happy Mama Tales where you'll find a community of
                women who support each other through this journey of life!
              </p>
              <p className="mb-8 text-gray-600 text-xl">
                We're a group of women who all have a story to tell and passions
                to explore! You'll find a variety of topics here but overall
                we're passionate about creating that healthy, holistic
                wellbeing.
              </p>
              <p className="mb-8 text-gray-600 text-xl">
                We love to help each other build healthy habits that strengthen
                the mind, body, and soul.
              </p>
              <Link
                className="inline-flex items-center justify-center py-3 text-base font-medium text-center bg-[#f1a6a8] text-white border border-transparent rounded-full px-7 hover:bg-opacity-90"
                to={"/contact"}
              >
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Description;
