import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  serverTimestamp,
  getDocs,
  updateDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import { db, storage, auth, analytics } from "../config/firebase";
import { v4, v6 } from "uuid";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import Compress from "compress.js";
import imageCompression from "browser-image-compression";

// Normal Form
export const addDocToMessages = async (data) => {
  try {
    await addDoc(collection(db, "messages"), {
      name: String(data.name),
      email: String(data.email),
      phone: String(data.phone),
      timeOfCreation: serverTimestamp(),
    });

    logEvent(analytics, "normal_form_submission");
  } catch (err) {
    console.log(err);
  }
};

// Contact Form
export const addDocToContacts = async (data) => {
  try {
    await addDoc(collection(db, "contacts"), {
      firstName: String(data.firstName),
      lastName: String(data.lastName),
      message: String(data.message),
      email: String(data.email),
      phone: String(data.phone),
      timeOfCreation: serverTimestamp(),
    });
  } catch (err) {
    console.log(err);
  }
};

// Newsletter Form
export const addDocToNewsletter = async (data) => {
  try {
    await addDoc(collection(db, "newsletter"), {
      email: data.email,
      timeOfCreation: serverTimestamp(),
    });
  } catch (err) {
    console.log(err);
  }
};

const storeProductImage = async (productId, imageFile) => {
  try {
    const storageRef = ref(
      storage,
      `images/products/${productId}/${imageFile.name}`
    );
    await uploadBytes(storageRef, imageFile); // Upload the image
    const downloadURL = await getDownloadURL(storageRef); // Get the image URL
    return downloadURL; // Return the URL of the uploaded image
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

// Create new product from admin panel
export const createNewProduct = async (data) => {
  let productId = v6();

  // Upload all images asynchronously
  const uploadedImages = await Promise.all(
    data.images.map((image) => storeProductImage(productId, image))
  );

  try {
    await addDoc(collection(db, "products"), {
      productId: productId,
      title: data.title,
      price: data.price,
      shortDes: data.shortDes,
      longDes: data.longDes,
      amazonLink: data.amazonLink,
      category: data.category,
      tags: data.tags,
      images: uploadedImages, // Use the array of uploaded image URLs
      timeOfCreation: serverTimestamp(),
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateProduct = async (id, updatedData) => {
  try {
    const docRef = doc(db, "products", id);

    // Convert tags to an array if it's a string
    const tagsArray =
      typeof updatedData.tags === "string"
        ? updatedData.tags.split(",").map((tag) => tag.trim())
        : updatedData.tags;

    await updateDoc(docRef, {
      ...updatedData,
      tags: tagsArray,
    });
  } catch (error) {
    console.error("Error updating product:", error);
    throw error; // Optionally rethrow the error to handle it in the calling function
  }
};

export const updateBlog = async (id, updatedData) => {
  console.log(id, updatedData);
  try {
    const docRef = doc(db, "blogPosts", id);
    await updateDoc(docRef, {
      ...{ data: updatedData },
    });
  } catch (error) {
    console.error("Error updating blog:", error);
    throw error; // Optionally rethrow the error to handle it in the calling function
  }
};

// Fetch any collection data
export const fetchAll = async (collectionName) => {
  const collectionRef = collection(db, collectionName);

  const querySnapshot = await getDocs(collectionRef);
  let items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
};

// Place order
export const placeOrder = async (data) => {
  try {
    await addDoc(collection(db, "orders"), {
      firstName: String(data.firstName),
      lastName: String(data.lastName),
      qty: Number(data.qty),
      products: data.products,
      email: String(data.email),
      phone: String(data.phone),
      address: String(data.address),
      timeOfCreation: serverTimestamp(),
    });
  } catch (err) {
    console.log(err);
  }
};

// delete document
export const deleteDocument = async (collectionName, docId) => {
  try {
    await deleteDoc(doc(db, collectionName, docId));
  } catch (error) {
    console.error("Error deleting document: ", error);
    console.log("Error Deleting Document");
  }
};

// Fetch single product
export const fetchSingleProduct = async (docId) => {
  const docRef = doc(db, "products", docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return "No such doc found!";
  }
};

// Auth
export const newUser = async (email, password) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed Up
      const user = userCredential.user;
    })
    .catch((error) => {
      alert(error.message.split("/")[1].replace(").", ""));
    });
};

export const signInUser = async (email, password) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      alert("Logged In");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      if (errorCode == "auth/invalid-credential") {
        alert("Please Enter Valid Credentials to Log In");
      } else {
        alert(errorCode);
      }
    });
};

export const signOutUser = async () => {
  if (!auth.currentUser) return;
  signOut(auth)
    .then(() => {
      alert("Signed Out");
    })
    .catch((error) => {
      alert(error);
    });
};

// Convert timestamp into human readable date
export const getDate = (timeOfCreation) => {
  const d = new Date(
    timeOfCreation.seconds * 1000 + timeOfCreation.nanoseconds / 1000000
  ).toLocaleString();

  return d;
};
