import React from 'react'
import ProductCard from '../ProductCard/ProductCard'

function Products() {
  return (
    <div className="bg-[#0c3c7d] sm:pb-6 md:pb-8">
        <div className="flex flex-col justify-center items-center gap-2 px-8 py-16">
          <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold text-white p-4 text-center">
            Happy Mama Essentials
          </h1>
          <p className="px-8 lg:px-16 text-center text-white">
            Check out our favorite things to help women like you stay healthy, happy, thriving and progressing!
          </p>
        </div>
        <ProductCard/>
    </div>
  )
}

export default Products