import React from "react";

import { Outlet, NavLink } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "./ScrollToTop/ScrollToTop";

const Navbar = () => {
  return (
    <>
     <Header/>
      <Outlet />
      <Footer/>
      <Toaster/>
      <ScrollToTop/>
    </>
  );
};

export default Navbar;
