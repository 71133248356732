import React from "react";
import AboutImage from "../../assets/images/aboutImage.webp";

const AboutUs = () => {
  return (
    <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
      <div className="flex flex-col lg:flex-row justify-between gap-8">
        <div className="w-full lg:w-5/12 flex flex-col justify-center">
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
            About Us
          </h1>
          <p className="font-normal text-base leading-6 text-gray-600 ">
            I'm a happy mama of 4 beautiful kiddos and wife to an amazing hubby!
            I love creating, crafting, and connecting with our amazing Happy
            Mama Tales community! Feel free to reach out to me through the
            contact form below or email me directly with any questions,
            suggestions, or inquiries to work together!
          </p>
        </div>
        <div className="w-full lg:w-8/12 ">
          <img
            className="w-full h-full"
            src={AboutImage}
            alt="A group of People"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

// Need Iamges for Our Story
