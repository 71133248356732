import React from 'react'
import Banner from '../components/About/Banner'
import AboutUs from '../components/About/AboutUs'
import FAQ from '../components/About/FAQ'

function AboutPage() {
  return (
    <div>
        <Banner/>
        <AboutUs/>
        <FAQ/>
    </div>
  )
}

export default AboutPage