import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { ThemeProvider } from "@material-tailwind/react";

// Pages
import HomePage from "./pages/HomePage";
// import PageTwo from "./pages/PageTwo";
import ErrorPage from "./pages/ErrorPage";

// import ProductPage from "./pages/ProductPage";"
import ProductsListPage from "./pages/admin/ProductsListPage";
import MessagesPage from "./pages/admin/MessagesPage";
import NewsletterPage from "./pages/admin/NewsletterPage";
import AboutPage from "./pages/AboutPage";
import LearnToSerge from "./pages/LearnToSerge";
import ShopPage from "./pages/ShopPage";
import ContactPage from "./pages/ContactPage";
import ServicesPage from "./pages/ServicesPage";
import ProductInfo from "./components/ProductInfo/ProductInfo";
import BlogPage from "./pages/BlogPage";
import AddProductPage from "./pages/admin/AddProductPage";
import BlogDetailPage from "./pages/admin/BlogDetailPage";

// Components
import Layout from "./components/Layout";
import AdminNavbar from "./components/AdminNavbar";
import AdminBlogPage from "./pages/admin/AdminBlogPage";
// import CreateBlogPage from "./pages/admin/CreateBlogPage";
import ContactsPage from "./pages/admin/ContactsPage";
import Analytics from "./components/Analytics";
import EditProductPage from "./pages/admin/EditProductPage";
import CreateBlog from "./pages/admin/CreateBlog";
import EditBlogPage from "./pages/admin/EditBlogPage";

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/about-us", element: <AboutPage /> },
      { path: "/learn-to-serge", element: <LearnToSerge /> },
      // HMT Essentials
      { path: "/store", element: <ShopPage /> },

      { path: "/contact", element: <ContactPage /> },

      // There is no need of services page 
      // { path: "/services", element: <ServicesPage /> },
      
      { path: "/blog", element: <BlogPage /> },
      { path: "/blog/:id", element: <BlogDetailPage /> },
      // { path: "/pagetwo", element: <PageTwo /> },
      { path: "/product/:productId", element: <ProductInfo /> },
    ],
  },
  {
    path: "/admin",
    element: <AdminNavbar />,

    children: [
      { path: "/admin/", element: <MessagesPage /> },
      { path: "/admin/products", element: <ProductsListPage /> },
      { path: "/admin/messages", element: <MessagesPage /> },
      { path: "/admin/contacts", element: <ContactsPage /> },
      { path: "/admin/newsletter", element: <NewsletterPage /> },
      { path: "/admin/newproduct", element: <AddProductPage /> },
      { path: "/admin/blog", element: <AdminBlogPage /> },
      // { path: "/admin/createblog", element: <CreateBlogPage /> },
      { path: "/admin/createblog", element: <CreateBlog /> },
      { path: "/admin/blog/:id", element: <BlogDetailPage /> },
      { path: "/admin/editblog/:id", element: <EditBlogPage /> },
      { path: "/admin/analytics", element: <Analytics /> },
      { path: "/admin/editproduct/:id", element: <EditProductPage /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);
