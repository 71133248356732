import React, { useState } from 'react'
import {addDocToMessages} from '../../helpers/helpers'
import toast from 'react-hot-toast';

function Contact() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");


    const handleSubmit = async (e) => {
    e.preventDefault(); 

    const formData = {
      name,
      email,
      phone,
    };

    try {
      await addDocToMessages(formData);
      toast('Successfully joined the community!',
  {
    icon: '👏',
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff',
    },
  }
);
      setName('');
      setEmail('');
      setPhone('');
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('There was an error. Please try again.');
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center mx-auto bg-gray-100">
        <div className="flex flex-col justify-center items-center gap-2 px-8">
          <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold text-[#0c3c7d] p-4 text-center">
            Join the Happy Mama Community
          </h1>
          <p className="px-8 lg:px-16 text-center">
            Join the newsletter to connect with thousands of other women who are
            passionate about creating a happy, healthy life. We share stories,
            tutorials and ideas to connect through this journey of womanhood!
          </p>
        </div>

        <div className="w-full px-16 lg:px-32 py-8">
          <form onSubmit={handleSubmit}>
            <div>
              <p className="pl-2">Name*</p>
              <input
                className="w-full p-3 text-sm bg-white border-black border text-black outline-none rounded-md"
                placeholder="Enter your name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className='py-2'>
                  <p className="pl-2">Email*</p>
                  <input
                    className="w-full p-3 text-sm bg-white border-black border text-black outline-none rounded-md"
                    placeholder="Enter your email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className='py-2'>
                  <p className="pl-2">Phone*</p>
                  <input
                    className="w-full p-3 text-sm bg-white border-black border text-black outline-none rounded-md"
                    placeholder="Enter your phone number"
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
                <div className="flex items-center justify-center py-4">
                    <button className='py-3 px-6 bg-[#f1a6a8] rounded-md hover:bg-opacity-90'>Join The Club</button>
                </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Contact