import React, { useState } from "react";
import { createNewProduct } from "../helpers/helpers";
import { Link } from "react-router-dom";

const AddProduct = ({ getList }) => {
  // Add product form states
  const [productTitle, setProductTitle] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productTags, setProductTags] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [localTag, setLocalTag] = useState("");
  const [amazonLink, setAmazonLink] = useState("");
  const [imageOne, setImageOne] = useState(null);
  const [imageTwo, setImageTwo] = useState(null);

  const [showAlert, setShowAlert] = useState(false);
  const [showImageAlert, setShowImageAlert] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (imageOne == null || imageTwo == null) {
      setShowImageAlert(true);
      return;
    }

    const data = {
      title: productTitle,
      description: productDescription,
      price: productPrice,
      category: productCategory,
      amazonLink: amazonLink,
      tags: productTags,
      images: [imageOne, imageTwo],
    };

    createNewProduct(data);

    setProductTitle("");
    setProductPrice("");
    setProductDescription("");
    setProductCategory("");
    setProductTags([]);
    setProductImages([]);
    setAmazonLink("");
    setLocalTag([]);
    setImageOne(null);
    setImageTwo(null);
    setShowAlert(false);
    setShowImageAlert(false);
    setShowSuccess(true);
    getList();
  };

  const handleAddBtn = () => {
    document.getElementById("my_modal_1").showModal();
    setShowSuccess(false);
  };
  return (
    <div className="p-12">
      {/* Open the modal using document.getElementById('ID').showModal() method */}
      <Link to="/admin/newproduct">
        <button className="btn btn-primary">Add New Product</button>
      </Link>
      <dialog id="my_modal_1" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Add New Product:</h3>
          <p className="py-4">
            Press ESC key or click the button below to close
          </p>
          {/* Add product form */}
          <div>
            {showAlert && (
              <div>
                <div role="alert" className="alert alert-error">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 shrink-0 stroke-current"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>Error! Please Fill All Values.</span>
                </div>
              </div>
            )}
          </div>
          <div>
            {showImageAlert && (
              <div>
                <div role="alert" className="alert alert-error">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 shrink-0 stroke-current"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>Error! Please Add Both Images.</span>
                </div>
              </div>
            )}
          </div>
          <div>
            {showSuccess && (
              <div>
                <div role="alert" className="alert alert-success">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 shrink-0 stroke-current"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>Product has been created!</span>
                </div>
              </div>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-row gap-7 items-center">
              <label className="w-64">Title</label>
              <input
                type="text"
                placeholder="dog collar"
                className="input input-bordered w-full max-w-xs"
                value={productTitle}
                onChange={(e) => setProductTitle(e.target.value)}
                required
              />
            </div>
            <div className="flex flex-row gap-7 items-center">
              <label className="w-64">Description</label>
              <input
                type="text"
                placeholder="this dog collar provides support for the dogs neck "
                className="input input-bordered w-full max-w-xs"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                required
              />
            </div>
            <div className="flex flex-row gap-7 items-center">
              <label className="w-64">Price($)</label>
              <input
                type="number"
                placeholder="20"
                className="input input-bordered w-full max-w-xs"
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
                required
              />
            </div>

            <div className="flex flex-row gap-7 items-center">
              <label className="w-64">Amazon Link</label>
              <input
                type="text"
                placeholder="20"
                className="input input-bordered w-full max-w-xs"
                value={amazonLink}
                onChange={(e) => setAmazonLink(e.target.value)}
                required
              />
            </div>
            <div className="flex flex-row gap-7 items-center">
              <label className="w-64">Category</label>
              <input
                type="text"
                placeholder="pet care"
                className="input input-bordered w-full max-w-xs"
                value={productCategory}
                onChange={(e) => setProductCategory(e.target.value)}
                required
              />
            </div>
            <div className="flex flex-row gap-7 items-center">
              <label className="w-64">Tags</label>
              <input
                type="text"
                placeholder="pets, dogs, collars, comfort"
                className="input input-bordered w-full max-w-xs"
                value={productTags}
                onChange={(e) => setProductTags(e.target.value)}
                required
              />
            </div>
            <div className="flex flex-row gap-7 items-center">
              <label className="w-64">Image One</label>
              <input
                type="file"
                className="file-input file-input-bordered w-full max-w-xs"
                onChange={(e) => setImageOne(e.target.files[0])}
                required
              />
            </div>
            <div className="flex flex-row gap-7 items-center">
              <label className="w-64">Image Two</label>
              <input
                type="file"
                className="file-input file-input-bordered w-full max-w-xs"
                onChange={(e) => setImageTwo(e.target.files[0])}
                required
              />
            </div>
            <input
              type="submit"
              value="Create Product"
              className="btn btn-ghost ml-60 mt-6"
            />
          </form>
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn">Close</button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default AddProduct;
