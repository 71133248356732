import Header from "@editorjs/header";
import SimpleImage from "@editorjs/simple-image";
import CheckList from "@editorjs/checklist";
import ImageTool from "@editorjs/image";

export const EDITOR_JS_TOOLS = {
  header: Header,
  checkList: CheckList,
  image: {
    class: ImageTool,
    config: {
      field: "file",
      endpoints: {
        byFile: "http://localhost:4000/upload", // Your backend file uploader endpoint
        byUrl: "http://localhost:4000/upload", // Your endpoint that provides uploading by Url
      },
    },
  },
};
