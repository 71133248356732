import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const faqItems = [
  {
    id: 1,
    question: "Is Happy Mama Tales only for mothers?",
    answer:
      "Not at all! While many of our members are mothers, our community is open to all women who are passionate about holistic well-being and supporting each other on this journey of life. Whether you're a mother, aspiring mother, or simply looking to connect with like-minded women, you're welcome here!",
  },
  {
    id: 2,
    question: "What topics do you cover on Happy Mama Tales?",
    answer:
      "We cover a wide range of topics that promote a healthy, holistic lifestyle. From building healthy habits for the mind, body, and soul to sharing personal stories and experiences, there's something for everyone. Our goal is to support each other in creating a balanced and fulfilling life.",
  },
  {
    id: 3,
    question: "How can I participate in the community?",
    answer:
      "You can participate by joining our discussions, sharing your stories, and engaging with other members. We encourage you to contribute your experiences and insights, as this is a space for all of us to grow and support one another.",
  },
  {
    id: 4,
    question: "Do you offer resources for holistic well-being?",
    answer:
      "Yes! We provide a variety of resources, including articles, tutorials, and guides that focus on holistic well-being. Our community members also share their tips and strategies for maintaining a healthy mind, body, and soul.",
  },
  {
    id: 5,
    question: "Is there a cost to join Happy Mama Tales?",
    answer:
      "Happy Mama Tales is free to join! We believe in creating an inclusive and supportive environment where women can connect and share their journeys without barriers.",
  },
];


function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="py-10 sm:py-16 lg:py-24 bg-black">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight text-gray-100 sm:text-4xl lg:text-5xl">
            Frequently Asked Questions
          </h2>
          <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-300">
            Welcome to our FAQ section! Here you'll find answers to common
            questions about us.
          </p>
        </div>

        <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
          {faqItems.map((item, index) => (
            <div
              key={index}
              className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50"
              onClick={() => toggleAnswer(index)}
            >
              <button
                type="button"
                className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
              >
                <span className="text-lg font-semibold text-black">
                  {item.question}
                </span>
                {activeIndex === index ? (
                  <FaChevronUp className="w-6 h-6 text-gray-400" />
                ) : (
                  <FaChevronDown className="w-6 h-6 text-gray-400" />
                )}
              </button>

              {activeIndex === index && (
                <div key={item.id} className="px-4 pb-5 sm:px-6 sm:pb-6">
                  <p>{item.answer} </p>
                </div>
              )}
            </div>
          ))}
        </div>

        <p className="text-center text-gray-300 text-base mt-9">
          Didn&apos;t find the answer you are looking for?{" "}
          <Link
            to={"/contact"}
            className="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline"
          >
            Contact our support
          </Link>
        </p>
      </div>
    </section>
  );
}

export default FAQ;