import React, { useState, useEffect } from "react";

// Routing
import { Outlet, Link } from "react-router-dom";

// Auth
import { auth } from "../config/firebase";
import { signOutUser } from "../helpers/helpers";
import { onAuthStateChanged } from "firebase/auth";

import AdminLogin from "./AdminLogin";

const AdminNavbar = () => {
  const [authState, setAuthState] = useState(auth.currentUser ? true : false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setAuthState(true);
      } else {
        setAuthState(false);
      }
    });
  }, []);

  const handleSignOut = () => {
    signOutUser();
  };

  if (authState == true) {
    return (
      <>
        <div className="navbar bg-base-100">
          <div className="navbar-start">
            <div className="dropdown">
              <div
                tabIndex={0}
                role="button"
                className="btn btn-ghost lg:hidden"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
              >
              <li>
                <Link to="/admin/messages">Messages</Link>
              </li>
              <li>
                <Link to="/admin/contacts">Contacts</Link>
              </li>
              <li>
                <Link to="/admin/newsletter">Newsletter</Link>
              </li>
              <li>
                <Link to="/admin/products">Products List</Link>
              </li>
              <li>
                <Link to="/admin/newproduct">New Product</Link>
              </li>
              <li>
                <Link to="/admin/blog">Blogs</Link>
              </li>
              <li>
                <Link to="/admin/createblog">Create Blog</Link>
              </li>
              <li>
                <Link to="/admin/analytics">Analytics</Link>
              </li>
              </ul>
            </div>
            <Link to="/admin" className="btn btn-ghost mr-64 text-base md:text-xl">
              Admin Panel - Happy Mama Tales
            </Link>
          </div>
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal px-1">
              <li>
                <Link to="/admin/messages">Messages</Link>
              </li>
              <li>
                <Link to="/admin/contacts">Contacts</Link>
              </li>
              <li>
                <Link to="/admin/newsletter">Newsletter</Link>
              </li>
              <li>
                <Link to="/admin/products">Products List</Link>
              </li>
              <li>
                <Link to="/admin/newproduct">New Product</Link>
              </li>
              <li>
                <Link to="/admin/blog">Blogs</Link>
              </li>
              <li>
                <Link to="/admin/createblog">Create Blog</Link>
              </li>
              <li>
                <Link to="/admin/analytics">Analytics</Link>
              </li>
            </ul>
          </div>
          <div className="navbar-end">
            <a className="btn" onClick={handleSignOut}>
              Sign Out
            </a>
          </div>
        </div>
        <Outlet />
      </>
    );
  }

  return (
    <>
      <AdminLogin />
    </>
  );
};

export default AdminNavbar;
