import React from 'react';

function Banner() {
  return (
    <section className="bg-gray-900 text-white">
      <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
        <div className="mx-auto max-w-3xl text-center">
           <h1
            className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl"
          >
            Empower. Connect. Thrive.

            <span className="sm:block">Holistic Well-being Together. </span>
          </h1>

          <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
            Join a community of women dedicated to supporting each other on the journey of life. 
            Let's grow together in mind, body, and soul.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Banner;
