import { FaChevronDown } from "react-icons/fa6";

function Banner() {
  return (
    <section className="bg-gray-50">
  <div className="mx-auto max-w-screen-xl px-4 py-16 lg:flex lg:h-full lg:items-center">
    <div className="mx-auto max-w-xl text-center">
      <h1 className="text-3xl font-extrabold sm:text-5xl">
        Exclusive Offer Upto
        <strong className="font-extrabold text-[#DA2778] sm:block">50% Off.</strong>
      </h1>

      <p className="mt-4 sm:text-xl/relaxed">
         Don’t miss out on our limited-time discount. Enhance your lifestyle with our curated products at half the price!
      </p>

      <div className="flex items-center justify-center p-4 ">
        <FaChevronDown size={20

        } className="animate-bounce duration-500 ease-in-out"/>
        
      </div>
    </div>
  </div>
</section>
  )
}

export default Banner