import React from "react";
import HeroSection from "../components/Home/HeroSection";
import Description from "../components/Home/Description";
import Contact from "../components/Home/Contact";
import Britni from "../components/Home/Britni";
import Products from "../components/Home/Products";
import Newsletter from "../components/Home/Newsletter";
import Blog from "../components/Blog/Blog";

const HomePage = () => {
  return <div>
    <HeroSection/>
    <Description/>
    <Blog/>
    <Contact/>
    <Products/>
    <Britni/>
    <Newsletter/>
  </div>;
};

export default HomePage;
