import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAll } from "../../helpers/helpers";
import { FaCartPlus } from "react-icons/fa";

function ProductCard() {
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      try {
        const allProducts = await fetchAll("products");
        setProductData(allProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    getProducts();
  }, []);

  const handleCardClick = (item) => {
    navigate(`/product/${item.id}`, {
      state: { ...item },
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-blue-600" />
      </div>
    );
  }

  return (
    <div className="font-[sans-serif] py-4 mx-auto lg:max-w-7xl max-w-lg md:max-w-full p-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {productData.map((item) => (
          <div
            key={item.id}
            className="bg-gray-200 rounded-xl cursor-pointer hover:scale-[1.03] transition-all relative overflow-hidden border-2"
            onClick={() => handleCardClick(item)}
          >
            {/* Image Container */}
            <div className="relative w-full h-[250px]">
              <img
                src={item.images[0]}
                alt={item.title}
                className="absolute top-0 left-0 w-full h-full object-cover" 
              />
            </div>

            {/* Product Details */}
            <div className="text-center bg-gray-100 p-6">
              <h3 className="text-lg font-bold text-gray-800">{item.title}</h3>
              <h4 className="text-lg text-gray-800 font-bold mt-6">
                ${item.price}
              </h4>

              <button
                type="button"
                className="w-full flex items-center justify-center gap-3 mt-6 px-6 py-3 bg-[#0c3c7d] text-base text-gray-100 font-semibold rounded-xl hover:opacity-85"
              >
                <FaCartPlus size={20} />
                Buy Now
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductCard;
