import React, { useState } from "react";
import { db, storage } from "../../config/firebase"; // Import Firestore and Storage
import { collection, addDoc } from "firebase/firestore";
import Editor from "./Editor";
import axios from "axios";

const INITIAL_DATA = null;

const CreateBlog = () => {
  const [data, setData] = useState(INITIAL_DATA);
  const [blogTitle, setBlogTitle] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [urlSlug, setUrlSlug] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [mainImage, setMainImage] = useState(null);

  const saveBlogPost = async () => {
    // Saving main image file to local server
    if (!mainImage) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", mainImage);

    let mainImageUrl = "";
    try {
      const response = await axios.post(
        "http://localhost:4000/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      mainImageUrl = response.data.file.url;
      console.log("File uploaded successfully:", response.data.file.url);
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    const blogPost = {
      title: blogTitle,
      seoTitle: seoTitle,
      metaDescription: metaDescription,
      urlSlug: urlSlug,
      data: data,
      mainImage: mainImageUrl,
      createdAt: new Date(),
    };

    try {
      const docRef = await addDoc(collection(db, "blogPosts"), blogPost);
      console.log("Document written with ID: ", docRef.id);
      alert("Blog post saved successfully!");
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Error saving blog post!");
    }
  };

  return (
    <>
      <button
        onClick={saveBlogPost}
        className="bg-black text-white px-6 py-3 roudned-lg absolute top-250 right-10 rounded-lg"
      >
        Save Post
      </button>
      <div className="flex flex-col gap-2 max-w-lg items-center justify-center mx-auto mb-2">
        <label className="self-start">Blog title</label>
        <input
          className="input border-2 border-black w-full"
          type="text"
          value={blogTitle}
          onChange={(e) => setBlogTitle(e.target.value)}
        />
      </div>

      <div className="max-w-5xl rounded-md border-2 border-black mx-auto p-2">
        <Editor
          data={data}
          onChange={setData}
          editorBlock="editorjs-container"
          readOnly={false}
        />
      </div>

      <div className="max-w-lg my-12 px-4 flex flex-col items-center justify-center gap-4 mx-auto">
        <div className="flex flex-col gap-2 w-full">
          <label>SEO TITLE</label>
          <input
            className="input border-2 border-black"
            type="text"
            value={seoTitle}
            onChange={(e) => setSeoTitle(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <label>URL Slug</label>
          <input
            className="input border-2 border-black"
            type="text"
            value={urlSlug}
            onChange={(e) => setUrlSlug(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <label className="">Meta Description</label>
          <input
            className="input border-2 border-black"
            type="text"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <label>Main Image</label>
          <input
            className="border-black"
            type="file"
            onChange={(e) => setMainImage(e.target.files[0])}
          />
        </div>

        {/* <button
        className="btn px-6 py-3 bg-black text-white hover:bg-gray-800 rounded-lg"
        onClick={() => console.log(data)}
      >
        Log Data
      </button> */}
      </div>
      {/*  */}
    </>
  );
};

export default CreateBlog;
