// src/components/BlogDetail.js
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { db } from "../../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { FaArrowLeftLong } from "react-icons/fa6";

import Editor from "./Editor";
import { updateBlog } from "../../helpers/helpers";

const EditBlogPage = () => {
  const [data, setData] = useState(null);
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      const blogDoc = doc(db, "blogPosts", id);
      const blogSnapshot = await getDoc(blogDoc);

      if (blogSnapshot.exists()) {
        setBlog(blogSnapshot.data());
        setData(blogSnapshot.data());
      } else {
        console.error("No such document!");
      }
    };

    fetchBlog();
  }, [id]);

  const saveBlogPost = async () => {
    try {
      await updateBlog(id, data);
      alert("Blog Post Saved!");
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Error saving blog post!");
    }
  };

  if (!blog) {
    return <p>Blog doesn't exists</p>;
  }

  return (
    <>
      <button
        onClick={saveBlogPost}
        className="bg-black text-white px-6 py-3 roudned-lg absolute top-250 right-10 rounded-lg"
      >
        Save Post
      </button>
      <div className=" max-w-3xl mx-auto mt-10 p-4 bg-white rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
        <Editor
          data={blog.data}
          editorBlock="editorjs-container"
          onChange={setData}
          readOnly={false}
        />
      </div>

      <div className=" flex items-end justify-end p-3">
        <Link
          to={"/blog"}
          className="inline-flex items-center rounded-md border border-black px-3 py-2 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
        >
          <FaArrowLeftLong size={16} className="mr-2" />
          Go to Blog
        </Link>
      </div>
    </>
  );
};

export default EditBlogPage;
