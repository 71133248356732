import React from "react";
import { deleteDocument } from "../helpers/helpers";
import { Link } from "react-router-dom";

const Product = ({
  title,
  description,
  price,
  tags,
  category,
  images,
  timeOfCreation,
  productId,
  id,
  getList,
  amazonLink,
}) => {
  const handleDelete = () => {
    deleteDocument("products", id);
    getList();
  };

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white border">
      {/* Image Section */}
      <div className="relative h-64 w-full">
        <img
          src={`${images[0]}`}
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>

      {/* Card Body */}
      <div className="p-4">
        {/* Amazon Link */}
        <a href={`${amazonLink}`} target="_blank" rel="noopener noreferrer">
          <p className="text-sm text-blue-500 underline break-words mb-2">
            {amazonLink}
          </p>
        </a>

        {/* Title and Price */}
        <h2 className="text-xl font-bold mb-2">
          {title} - <span className="text-green-600">${price}</span>
        </h2>

        {/* Category Badge */}
        <div className="mb-4">
          <span className="inline-block bg-blue-100 text-blue-800 text-sm font-semibold px-4 py-1 rounded-full">
            {category}
          </span>
        </div>

        {/* Tags */}
        <div className="mb-4">
          <h4 className="text-sm font-semibold mb-2">Tags:</h4>
          <div className="flex flex-wrap gap-2">
            {tags.map((tag) => (
              <span
                key={tag}
                className="inline-block bg-gray-200 text-gray-700 px-3 py-1 text-xs font-medium rounded-full"
              >
                {tag}
              </span>
            ))}
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between mt-4">
          <button
            className="btn btn-outline btn-secondary"
            onClick={() => handleDelete()}
          >
            Delete
          </button>
          <Link to={`/admin/editproduct/${id}`}>
            <button className="btn btn-outline btn-primary">Edit</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Product;
