import {Link} from 'react-router-dom'
import LearnToSergeImage from '../assets/images/learnToSerge.webp'

function LearnToSerge() {
  return (
    <>
    <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 mt-4">
        <div className="p-3">
            <h1 className='text-2xl md:text-4xl lg:text-5xl font-bold mb-4'>Learn to Serge Home Page</h1>

            <p className='mb-4'>Hi there and welcome to my Learn to Serge Series! I created this comprehensive serging curriculum 4 years ago. I began with a vision where I wanted to teach everyone how to serge. The amount of positive response I’ve received from this Learn to Serge series has been overwhelmingly amazing. I am so glad for how it all started…</p>

            <p className='mb-4'>I began the Learn to Sew Series with Crazy Little Projects and it completely changed my life. Truly. I gained a better understanding of sewing which inspired me to pick up the serger (also known as an overlock machine). Once I had those down I was able to get a job as a professional seamstress. I want to add to the Learn to Sew Series and teach you how to serge once you understand the basics of sewing.</p>

            <p className='mb-4'>
                This Learn to Serge series will teach you everything you want to know about serging with an overlock machine and put it in an easy to read ebook. I have compiled all my research and my experience of trial and error. The serger is an easy machine to use that can provide a HUGE advantage to your DIY crafts! Here is a list of the lessons within the ebook. Make sure to scroll down and purchase your copy of the Learn to Serge ebook!
            </p>
        </div>

     {/* 2nd Section */}
        <div className="p-3">
                <h1 className='text-xl md:text-3xl lg:text-4xl font-bold mb-4 text-[#eaa0a2]'>Pre-Lessons:</h1>
                <p className='mb-4'>Meet your Serger: Understanding Serger Features</p>
                <p className='mb-4'>Tension Test: Perfecting your tension</p>
                <p className='mb-4'>Tips and Tricks: Keep these in mind while serging any project</p>
                <p className='mb-4'>Serger Dictionary: Understand the serger lingo</p>
                <p className='mb-4'>What Supplies you will need: A list of the basic supplies you’ll need</p>
        </div>
    
     { /* 3rd Section */}
        <div className="p-3">
                <h1 className='text-xl md:text-3xl lg:text-4xl font-bold mb-4 text-[#eaa0a2]'>Learn to Serge Lessons:</h1>
                <p className='mb-4'>Lesson #1: Learn to thread your serge</p>
                <p className='mb-4'>Lesson #2: Start and Stop, Unpick a serged stitch</p>
                <p className='mb-4'>Lesson #3: Serge a straight line, serge with multiple layers</p>
                <p className='mb-4'>Lesson #4: Serging Circles</p>
                <p className='mb-4'>Lesson #5: Serge with elastic, serge ruffles</p>
                <p className='mb-4'>Lesson #6: Serge Corners</p>
                <p className='mb-4'>Lesson #7: Maintaining your serger</p>
        </div>

        {/*E-book Section   */}
        <div className=" flex flex-col items-center justify-center gap-4 mb-8">
            <p className='text-center'>I can’t wait to hear how YOU use your new serging skills. Once you’ve DOWNLOADED THE EBOOK, make sure to send me an email with your personal serging story. Can’t wait for you to join the serging family!!</p>

            <Link
                  className="inline-flex items-center justify-center py-3 text-base font-medium text-center bg-[#f1a6a8] text-white border border-transparent rounded-full px-7 hover:bg-opacity-90"
                >
                  Buy Now
                </Link>

            <img src={LearnToSergeImage} alt="learnToSerge"  className='p-3'/>
        </div>
    </div>
    </>
  )
}

export default LearnToSerge