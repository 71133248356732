import React, { useState } from "react";
import { createNewProduct } from "../../helpers/helpers";
import ProductImageUploader from "./ProductImageUploader";

const AddProductPage = () => {
  // Add product form states
  const [productTitle, setProductTitle] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [shortDes, setShortDes] = useState("");
  const [longDes, setLongDes] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [amazonLink, setAmazonLink] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [localTag, setLocalTag] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [showImageAlert, setShowImageAlert] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      title: productTitle,
      shortDes: shortDes,
      longDes: longDes,
      price: productPrice,
      category: productCategory,
      amazonLink: amazonLink,
      tags: localTag.split(","),
      images: productImages,
    };

    createNewProduct(data);

    setProductTitle("");
    setProductPrice("");
    setShortDes("");
    setLongDes("");
    setProductCategory("");
    setProductImages([]);
    setAmazonLink("");
    setLocalTag([]);
    setShowAlert(false);
    setShowImageAlert(false);
    setShowSuccess(false);
  };
  return (
    <div>
      <div className="p-12">
        {/* Open the modal using document.getElementById('ID').showModal() method */}
        <h3 className="font-bold text-lg text-center mb-2 md:text-2xl">
          Add New Product:
        </h3>
        <p className="py-6 text-center mb-4">
          Press ESC key or click the button below to close
        </p>
        <div>
          <div className="container lg:w-4/5 flex items-center justify-center">
            {/* Add product form */}
            <div>
              {showAlert && (
                <div>
                  <div role="alert" className="alert alert-error">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 shrink-0 stroke-current"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span>Error! Please Fill All Values.</span>
                  </div>
                </div>
              )}
            </div>
            <div>
              {showImageAlert && (
                <div>
                  <div role="alert" className="alert alert-error">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 shrink-0 stroke-current"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span>Error! Please Add Atleast 2 Images.</span>
                  </div>
                </div>
              )}
            </div>
            <div>
              {showSuccess && (
                <div>
                  <div role="alert" className="alert alert-success">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 shrink-0 stroke-current"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span>Product has been created!</span>
                  </div>
                </div>
              )}
            </div>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <div className="flex flex-col md:flex-row lg:gap-7 items-start">
                <label className="w-64">Title</label>
                <input
                  type="text"
                  placeholder="dog collar"
                  className="input input-bordered w-full max-w-xs"
                  value={productTitle}
                  onChange={(e) => setProductTitle(e.target.value)}
                  required
                />
              </div>
              <div className="flex flex-col md:flex-row lg:gap-7 items-start">
                <label className="w-64">Short Description</label>
                <textarea
                  type="text"
                  rows="8"
                  placeholder="this dog collar provides support for the dogs neck "
                  className="input input-bordered w-full max-w-xs"
                  value={shortDes}
                  onChange={(e) => setShortDes(e.target.value)}
                />
              </div>
              <div className="flex flex-col md:flex-row lg:gap-7 items-start">
                <label className="w-64">Long Description</label>
                <textarea
                  type="text"
                  rows="8"
                  placeholder="this dog collar provides support for the dogs neck "
                  className="input input-bordered w-full max-w-xs"
                  value={longDes}
                  onChange={(e) => setLongDes(e.target.value)}
                />
              </div>
              <div className="flex flex-col md:flex-row lg:gap-7 items-start">
                <label className="w-64">Price($)</label>
                <input
                  type="number"
                  placeholder="20"
                  className="input input-bordered w-full max-w-xs"
                  value={productPrice}
                  onChange={(e) => setProductPrice(e.target.value)}
                  required
                />
              </div>
              <div className="flex flex-col md:flex-row lg:gap-7 items-start">
                <label className="w-64">Amazon Link</label>
                <input
                  type="text"
                  placeholder="https://www.amazon.in/"
                  className="input input-bordered w-full max-w-xs"
                  value={amazonLink}
                  onChange={(e) => setAmazonLink(e.target.value)}
                />
              </div>
              <div className="flex flex-col md:flex-row lg:gap-7 items-start">
                <label className="w-64">Category</label>
                <input
                  type="text"
                  placeholder="pet care"
                  className="input input-bordered w-full max-w-xs"
                  value={productCategory}
                  onChange={(e) => setProductCategory(e.target.value)}
                  required
                />
              </div>
              <div className="flex flex-col md:flex-row lg:gap-7 items-start">
                <label className="w-64">Tags</label>
                <input
                  type="text"
                  placeholder="pets, dogs, collars, comfort"
                  className="input input-bordered w-full max-w-xs"
                  value={localTag}
                  onChange={(e) => setLocalTag(e.target.value)}
                  required
                />
              </div>

              {/* add images */}
              <div className="container">
                <ProductImageUploader setProductImages={setProductImages} />
              </div>

              <button
                type="submit"
                className="btn btn-primary btn-wide lg:ml-60 mt-6"
              >
                Create Product
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductPage;
